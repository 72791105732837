<template>
  <div />
</template>

<script>
import * as auth from "@/utils/auth";

export default {
  name: "LoginExternal",
  mounted: function () {
    this.login();
  },
  methods: {
    login: function () {
      //get token and expires_in from url query
      const cookieValObject = {
        token: this.$route.query.token,
        expires_in: parseInt(this.$route.query.expires_in) / 1000,
        expires: Date.now() + parseInt(this.$route.query.expires_in),
      };

      this.$store
        .dispatch("doAuthExternal", cookieValObject)
        .then(() => {
          if (this.$route.query.nextUrl != null) {
            this.$router.push(this.$route.query.nextUrl);
          } else {
            this.$router.push('/');
          }
        })
        .catch(() => {
          this.$router.push("/login");
        });
    },
  },
};
</script>

<style scoped></style>